import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import contact_img from "../images/undraw_contact_us_edited.svg";

const Contact = () => {
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Services" />

      <div
        className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
        style={{ backgroundImage: `url(${contact_img})` }}
      >
        <div className="container w-3/4 md:w-1/2 mt-20 font-serif font-light self-start">
          <h1 className="text-4xl md:text-5xl text-blue-700 px-2">
            Contact us
          </h1>
        </div>

        <div
          className="w-full sm:w-3/4 lg:w-1/2 mt-10 px-6 py-4 text-gray-800"
          style={{
            boxShadow:
              "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)",
          }}
        >
          <p>
            <strong>Phone:</strong> XXX XXX XXX
          </p>
          <p>
            <strong>Email: </strong>info@bayerntek.de
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
